import * as React from 'react';
import { NextPage } from 'next';
import Head from 'next/head';
import { Heading, Text } from '@components/typography';
import { ROUTES } from '@src/constants';
import { Box } from '@components/box';
import { pageTitle } from '@utils/string';
import { AppLayout } from '@components/app-layout';
import { AnchorButton } from '@components/button';

const PageNotFound: NextPage = () => {
  return (
    <>
      <Head>
        <title>{pageTitle('Page not found')}</title>
        <meta name="robots" content="noindex" />
      </Head>
      <AppLayout>
        <Box
          css={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            spaceY: '$6',
            py: '$10',
          }}
        >
          <Heading as="h1" variant="h1">
            404
          </Heading>
          <Text variant="default">Sorry this page doesn&apos;t exist any more.</Text>

          <AnchorButton href={ROUTES.home}>Back to home</AnchorButton>
        </Box>
      </AppLayout>
    </>
  );
};

export default PageNotFound;
